import(/* webpackMode: "eager", webpackExports: ["BlogPostCard"] */ "/vercel/path0/apps/vercel-docs/app/components/home-page/blog-post-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BlogPostSection"] */ "/vercel/path0/apps/vercel-docs/app/components/home-page/blog-post-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GuidesSection"] */ "/vercel/path0/apps/vercel-docs/app/components/home-page/guides-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/vercel/path0/apps/vercel-docs/app/components/home-page/hero.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/vercel-docs/app/components/home-page/home-page.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["ProductsSection"] */ "/vercel/path0/apps/vercel-docs/app/components/home-page/products-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LastUpdated"] */ "/vercel/path0/apps/vercel-docs/app/components/layout/lastupdated.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RecentArticles"] */ "/vercel/path0/apps/vercel-docs/app/components/recent-articles.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NoTemplateOnlyTemplatesSection"] */ "/vercel/path0/apps/vercel-docs/app/components/templates-section/no-template-only-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocalhostController"] */ "/vercel/path0/node_modules/.pnpm/@vercel+toolbar@0.1.18_next@15.0.0-canary.158_@opentelemetry+api@1.7.0_@playwright+test@1.44._wgx4jo56acvnruahulet7kj7ry/node_modules/@vercel/toolbar/dist/next/localhost-controller.js");
;
import(/* webpackMode: "eager", webpackExports: ["UnmountController"] */ "/vercel/path0/node_modules/.pnpm/@vercel+toolbar@0.1.18_next@15.0.0-canary.158_@opentelemetry+api@1.7.0_@playwright+test@1.44._wgx4jo56acvnruahulet7kj7ry/node_modules/@vercel/toolbar/dist/next/unmount.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.158_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@playwright+test@1.44.1_ba_22vhrroyeiaz63rx3fj7ykwjju/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.158_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@playwright+test@1.44.1_ba_22vhrroyeiaz63rx3fj7ykwjju/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.158_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@playwright+test@1.44.1_ba_22vhrroyeiaz63rx3fj7ykwjju/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/docs-shared/src/components/layout/content.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/components/feedback/feedback.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/components/image/image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PixelIcon"] */ "/vercel/path0/packages/geist/src/components/pixel-icon/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/components/skeleton/skeleton.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/utils/use-await-paint-select-state.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/utils/use-delayed-render.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/utils/use-isomorphic-layout-effect.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/utils/use-media-query.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/utils/with-blur-base-64.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/vercel/path0/packages/multi-zone/src/link/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/test-utils/src/use-hydration-sentinal.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useParams"] */ "/vercel/path0/packages/vercel-segment/src/hooks/use-params.ts");
;
import(/* webpackMode: "eager", webpackExports: ["usePathname"] */ "/vercel/path0/packages/vercel-segment/src/hooks/use-pathname.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useSelectedLayoutSegment"] */ "/vercel/path0/packages/vercel-segment/src/hooks/use-selected-layout-segment.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useSelectedLayoutSegments"] */ "/vercel/path0/packages/vercel-segment/src/hooks/use-selected-layout-segments.ts");
